import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";


const BlogColumn = ({ 
  title,
  author,
  category,
  featuredImage,
  altText,
  pageLink
}) => {
  return (
    <div className="mb-8 lg:mb-0">
      <Link
        to={ pageLink }
      >
        <Img
          fluid={ featuredImage }
          alt={ altText }
        />
      </Link>
      <p className="pt-4 text-sm font-thin">{ category } &mdash; { author }</p>
      <h3 className="pt-2 text-2xl font-thin">
        <span>{ title }</span>
      </h3>
    </div>
  )
}

export default BlogColumn;
